const TIPOLOGIERECUPERI = {
  UF_SIN_RAMO_CAUZIONI: ['Ufficio sinistri ramo cauzioni', 'Oficina de siniestros ramo cauciones'],
  UF_SIN_RAMI_ELEMENTARI: ['Ufficio sinistri rami elementari', 'Oficina de siniestros ramos elementales'],
  UF_SIN_RISCHI_AGRICOLO: ['Ufficio sinistri rischi agricolo', 'Oficina de siniestros riesgos agricolas'],
  UF_SIN_MARINE: ['Ufficio sinistri Marine', 'Oficina de siniestros Maritimos'],
  UF_SIN_SPECIALTIES: ['Ufficio sinistri Specialties', 'Oficina de siniestros Especialidades'],
}

const ISPETTORATO = {
  ASSICURATO: ['Assicurato', 'Asegurado'],
  TERZO: ['Terzo', 'Tercero']
}
const ESITI = {
  SINISTRO_APERTO: ['sinistro aperto', 'siniestro abierto'],
  SINISTRO_CHIUSO: ['sinistro chiuso', 'siniestro cerrado'],
  IN_AGENDA: ['in agenda', 'en agenda'],
  SEGNA_DUPLICATO: ['segna come duplicato', 'marcar como duplicado'],
  CAUSA_ATTESA_INCARICO: ['causa in attesa incarico', 'causa en espera de asignacion'],
  CAUSA_ATTESA_UDIENZA: ['causa in attesa udienza', 'causa en espera de audiencia'],
  DINIDEGO_RIFIUTATO_INTEGRAZIONE: ['diniego rifiutato con integrazione', 'denegacion rechazada con integracion'],
  CALENDAR: ['calendar', 'calendario'],
  SHELL_CLAIM: ['shell claim', 'reclamacion shell'],
  DENUNCIA_RIATTIVATA: ['denuncia riattivata', 'denuncia reactivada'],
  SINISTRO_APERTO_DA_SHELL_CLAIM: ['sinistro aperto shell', 'siniestro abierto shell'],
  SUPERAMENTO_AUTHORITY_APPROVATO: ['superamento authority approvato', 'superacion de autoridad aprobada'],
  SUPERAMENTO_AUTHORITY_RIFIUTATO: ['superamento authority rifiutato', 'superacion de autoridad denegada'],
  VAL_PAGAMENTO_ACCETTATA: ['validazione pagamento accettata', 'validacion de pago aceptada'],
  VAL_PAGAMENTO_RIFIUTATA: ['validazione pagamento rifiutata', 'validacion de pago denegada'],
  RICHIESTA_AUTORIZZAZIONE: ['richiesta autorizzazione', 'solicitud de autorizacion'],
  FIDUCIARIO_NOMINATO: ['fiduciario nominato', 'fideicomisario designado'],
  INCARICO_COMPLETATO: ['incarico completato', 'encargo completado'],
  INCARICO_ANNULLATO: ['incarico annullato', 'encargo cancelado'],
  CAUSA_APERTA: ['causa aperta', 'causa abierta'],
  CAUSA_CHIUSA: ['causa chiusa', 'causa cerrada'],
  INVIO_ORDINE_PAGAMENTO: ['invio ordine di pagamento', 'envio de orden de pago'],
  QUIETANZA_EMESSA: ['quietanza emessa', 'recibo emitido'],
  QUIETANZA_EMESSA_AGENDA: ['quietanza emessa in agenda', 'recibo emitido en agenda'],
  QUIETANZA_ANNULLATA: ['annullata', 'anulada'],
  PAGAMENTO_INDENNIZZO_NON_ACCETTATO: ['pagamento indennizzo non accettato', 'pago de indemnizacion no aceptado'],
  PAGAMENTO_INDENNIZZO_AGENDA: ['pagamento indennizzo in agenda', 'pago de indemnizacion en agenda'],
  PAGAMENTO_SPESE_AGENDA: ['pagamento spese in agenda', 'pago de gastos en agenda'],
  INCARICO_AGENDA: ['incarico in agenda', 'encargo en agenda'],
  RICHIESTA_INTEGRAZIONE_AGENDA: ['richiesta integrazione agenda', 'solicitud de integracion en agenda'],
  PAGAMENTO_ANNULLATO: ['pagamento annullato', 'pago anulado'],
  QUIETANZA_NON_ACCETTATA: ['quietanza non accettata', 'recibo no aceptado'],
  PAGAMENTO_EFFETTUATO: ['pagamento effettuato', 'pago efectuado'],
  CHIUSURA_INCARICO: ['chiusura incarico', 'cierre de encargo'],
  RIATTIVAZIONE_INCARICO: ['riattivazione incarico', 'reactivacion de encargo'],
  CAUSA_ANNULLATA: ['causa annullata', 'causa anulada'],
  APERTURA_RECUPERO: ['apertura recupero', 'apertura de recuperacion'],
  RIAPERTURA_RECUPERO: ['riapertura recupero', 'reapertura de recuperacion'],
  CHIUSURA_RECUPERO: ['chiusura recupero', 'cierre de recuperacion'],
  INCASSO_RECUPERO: ['incasso recupero', 'cobro de recuperacion'],
  RECUPERO_ANNULLATO: ['recupero annullato', 'recuperacion anulada'],
  IN_VALIDAZIONE: ['in validazione', 'en validación'],
  SENZA_SEGUITO: ['senza seguito', 'sin seguimiento'],
  SINISTRO_RIAPERTO: ['sinistro riaperto', 'siniestro reabierto'],
  SINISTRO_IN_LAVORAZIONE: ['sinistro in lavorazione', 'siniestro en proceso'],
  SINISTRO_PARZIALE: ['sinistro parziale', 'siniestro parcial'],
  SINISTRO_CHIUSO_TOTALE: ['sinistro chiuso totale', 'siniestro cerrado total'],
  SINISTRO_COMPLETATO: ['sinistro completato', 'siniestro completado'],
  RIASSEGNA_SINISTRO: ['riassegna sinistro', 'reasignar siniestro']
};

const CRITERI = {
  DENUNCIA: ['denuncia', 'denuncias'],
  ISTRUTTORIA: ['istruttoria', 'investigacion', 'istruttoria chiusa', 'investigacion cerrada'],
  SHELL_CLAIM: ['shell claim', 'reclamacion shell'],
  RE_DENUNCIA: ['re - denuncia', 're - denuncias'],
  RE_INCARICO: ['re - incarico', 're - asignacion encargo'],
  RE_INCARICO_NOMINATO: ['re - incarico nominato', 're - asignacion designada'],
  RE_PAGAMENTO_SPESE: ['re - pagamento spese', 're - pago (de) gastos'],
  TL_INCARICO: ['tl - incarico', 'tl - asignacion encargo'],
  TL_DENUNCIA: ['tl - denuncia', 'tl - denuncia'],
  TL_ISTRUTTORIA: ['tl - istruttoria', 'tl - investigacion'],
  TL_INCARICO_NOMINATO: ['tl - incarico nominato', 'tl - asignacion designada'],
  TL_PAGAMENTO_SPESE: ['tl - pagamento spese', 'tl - pago de gastos'],
  TP_ISTRUTTORIA: ['tp - istruttoria', 'tp - investigacion'],
  MN_ISTRUTTORIA: ['mn - istruttoria', 'mn - investigacion'],
  MN_INCARICO: ['mn - incarico', 'mn - asignacion encargo'],
  MN_DENUNCIA: ['mn - denuncia', 'mn - denuncia'],
  MN_INCARICO_NOMINATO: ['mn - incarico nominato', 'mn - asignacion designada'],
  MN_DENUNCIA_RIATTIVATA: ['mn - denuncia - riattivata', 'mn - denuncia - reactivada'],
  MN_SHELL_CLAIM: ['mn - shell claim', 'mn - reclamacion shell'],
  MN_PAGAMENTO_SPESE: ['mn - pagamento spese', 'mn - pago de gastos'],
  QUIETANZA: ['quietanza', 'recibo (de) pago'],
  PAGAMENTO_INDENNIZZO: ['pagamento indennizzo', 'pago (de) indemnizaciones'],
  PAGAMENTO_INDENNIZZO_INVIATO: ['pagamento indennizzo inviato', 'pago de indemnizacion enviado'],
  ISTRUTTORIA_CHIUSA: ['istruttoria chiusa', 'investigacion cerrada'],
}

 const TIPOLOGIESIDEBAR = {
  DENUNCIA: ['denuncia', 'denuncia'],
  SINISTRO: ['sinistro', 'siniestro'],
  CAUSA: ['causa', 'causa'],
  INCARICO: ['incarico', 'asignacion'],
  VALIDAZIONI: ['validazioni', 'validaciones'],
  PAGAMENTO_SPESE: ['pagamento spese', 'pago de gastos'],
  PAGAMENTO_INDENNIZZO: ['pagamento indennizzo', 'pago de indemnizacion'],
  VALIDAZIONE_RISERVA_INDENNIZZO: ['validazione riserva indennizzo', 'validación de la reserva de indemnización'],
  VALIDAZIONE_RISERVA_SPESE: ['validazione riserva spese', 'validación de la reserva de gastos'],
  VALIDAZIONE_PAGAMENTO_INDENNIZZO: ['validazione pagamento indennizzo', 'validación de pago de la indemnización'],
  VALIDAZIONE_PAGAMENTO_SPESE: ['validazione pagamento spese', 'validación de pago de gastos'],
  AUTORIZZAZIONE_LIMITE_RISERVATO: ['autorizzazione limite riservato', 'autorizacion de limite reservado'],
  AUTORIZZAZIONE_DINIEGO: ['autorizzazione per diniego', 'autorizacion de denegacion'],
  AUTORIZZAZIONE_LIMITE_PAGAMENTO: ['autorizzazione limite pagamento', 'autorizacion de limite de pago'],
  APPUNTAMENTO: ['appuntamento', 'cita'],
  APPUNTAMETO_2: ['appuntamento 2', 'cita 2'],
  RECUPERO: ['recupero', 'recuperacion'],
}

const TIPOPAGAMENTO = {
  LQIND: 'LQIND',
  LQSPE: 'LQSPE',
}

const EnumService = {
  TIPOLOGIERECUPERI,
  ISPETTORATO,
  ESITI,
  CRITERI,
  TIPOLOGIESIDEBAR,
  TIPOPAGAMENTO
}

export default EnumService;