import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import TicketReducer from './app/ticket/TicketSlice';
import AddNavTaskReducer from './app/addNavTask/AddNavTaskSlice';
import EmailReducer from './apps/email/EmailSlice';
import EventsReducer from './app/agenda/EventSlice';
import AllegatiReducer from './app/allegati/AllegatiSlice';
import TabDiarioNoteReducer from './app/diarioTrattazione/NoteSlice';
import WsReducer from './app/ws/WsSlice';
import TipologieForm from './app/formTipoligie/TicketFormTipologie';
import RiserveAuthorityReducer from './app/riserveAuthority/RiserveAuthoritySlice';
import CheckListReducer from './app/CheckListEsiti/CheckListSlice';
import FinancialsReducer from './app/financialsTab/FinancialsSlice';
import RicercaSinistriReducer from './app/ricercaSinistro/RicercaSinistriSlice';
import MultiValutaSliceReducer from './app/multiValuta/MultiValutaSlice';
import PkceSliceReducer from './app/pkce/PkceSlice';
import EventiCatastrofaliReducer from './app/EventiCatastrofali/EventiCatastrofaliSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    ticketReducer: TicketReducer,
    addNavTaskReducer: AddNavTaskReducer,
    emailReducer: EmailReducer,
    eventsReducer: EventsReducer,
    allegatiReducer: AllegatiReducer,
    tabDiarioNoteReducer: TabDiarioNoteReducer,
    wsReducer : WsReducer,
    tipologieFormReducer: TipologieForm,
    riserveAuthorityReducer:RiserveAuthorityReducer,
    checkListReducer:CheckListReducer,
    financialsReducer: FinancialsReducer,
    ricercaSinistriReducer: RicercaSinistriReducer,
    multiValutaSliceReducer: MultiValutaSliceReducer,
    pkceReducer: PkceSliceReducer,
    eventiCatastrofali: EventiCatastrofaliReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
});

export default store;
